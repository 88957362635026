import Model, { attr } from '@ember-data/model';

import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { service } from '@ember/service';

export default class ProductModel extends Model {
  @service intl;

  @attr('string') productNumber;
  @attr('string') name;
  @attr('string') description;
  @attr('string') provider;
  @attr('string') sku;
  @attr('string') billableSku;
  @attr('string') sku;
  @attr('string') nameEn;
  @attr('string') descriptionEn;
  @attr('boolean', { defaultValue: false }) isBillable;
  @fragmentArray('product/i18n', { async: false }) i18n;

  get intlName() {
    const currentLocale = this.intl?.locale[0];
    return this.i18n.find((i18n) => i18n.language === currentLocale)?.name || this.name;
  }

  get intlDescription() {
    const currentLocale = this.intl?.locale[0];
    return this.i18n.find((i18n) => i18n.language === currentLocale)?.description || this.description;
  }
}

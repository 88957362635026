import Service, { service } from '@ember/service';

import { storageFor } from 'ember-local-storage';
import { tracked } from '@glimmer/tracking';

export default class DrawerService extends Service {
  @storageFor('settings') settings;
  @service layout;

  toggle() {
    this.settings.toggleProperty('drawer.opened');
    this.settings.set('drawer.opened', this.settings.get('drawer.opened'));
  }

  @tracked
  style = this.layout.isDesktop ? 'dismissible' : 'modal';
}

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmptyObject } from 'accounts-app/helpers/is-empty-object';

export default class NfeDataTablePaginationComponent extends Component {
  get isPreviousPageDisabled() {
    const { skip, isLoading, previousPage } = this.args;

    return previousPage.disabled || skip === 0 || isLoading;
  }

  get isNextPageDisabled() {
    const { isLoading, nextPage, data, perPage } = this.args;
    const dataLength = data.length;

    return nextPage.disabled || isLoading || dataLength < perPage || dataLength === 0;
  }

  get count() {
    const { perPage, skip, metaData } = this.args;

    if (!metaData || isEmptyObject(metaData)) return;

    let count = 1;
    let items = skip + perPage;
    let total = metaData.total;

    if (skip > 0) {
      count = skip;
    }

    if (items >= total) {
      items = total;
    }

    return `${count}-${items} de ${total}`;
  }

  @action
  onChangePerPage(perPage) {
    this.args.onChangePerPage(perPage.value);
  }
}

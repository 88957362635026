import RESTAdapter from '@ember-data/adapter/rest';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';

export default class ApplicationAdapter extends RESTAdapter {
  @service session;
  @service snackbar;
  @service intl;
  @service router;

  async handleResponse(status, headers, payload) {
    const response = await super.handleResponse(...arguments);

    if (status < 400) {
      this.session.data.tryRefreshToken = false;
      this.session.store.persist(this.session.data);
      return response;
    }

    if (isPresent(payload?.message)) {
      response.code = status;
      response.description = payload.message;
    }

    if (status === 401 && this.session.isAuthenticated) {
      if (this.session.data.tryRefreshToken) {
        return this.session.forceRefresh();
      }

      this.session.data.tryRefreshToken = true;
      this.session.store.persist(this.session.data);

      this.router.transitionTo('login', {
        queryParams: {
          redirect: window.location.pathname,
          refresh: true,
        },
      });
    }

    return response;
  }
}

import Model, { attr, belongsTo } from '@ember-data/model';

import Fragment from 'ember-data-model-fragments/fragment';

export default class I18nModel extends Fragment {
  @attr('string') language;
  @attr('string') name;
  @attr('string') description;

}

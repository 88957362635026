import Component from '@glimmer/component';
import arg from '../../../utils/decorators/arg';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class LoadersSubscriptionUsageSummaryComponent extends Component {
  @service store;

  @arg accountId;
  @arg subscriptionId;
  @arg date;

  @tracked usageSummary = [];
  @tracked error = false;

  @task({ drop: true })
  *getUsageSummary() {
    try {
      const dateInstance = new Date(`${this.date} 23:59:59.0000Z`);
      const year = dateInstance.getUTCFullYear();
      const month = dateInstance.getUTCMonth() + 1;
      const usageSummaries = yield this.store
        .queryRecord('usage-summary', {
          accountId: this.accountId,
          subscriptionId: this.subscriptionId,
          date: { year, month },
        })
        .then(yield this.processUsageSummaries.bind(this));

      this.usageSummary = usageSummaries || {};
      return;
    } catch (error) {
      this.error = true;
    }
  }

  async processUsageSummaries(usageSummaries) {
    if (!usageSummaries) return {};

    const products = await this.store.findAll('product');

    usageSummaries.services = await usageSummaries.services.map((service) => {
      service.actions = service.actions.map((action) => {
        const product = products.findBy('billableSku', `${service.provider}.${service.resource}.${action.actionId}`);
        if (!product) console.log(`Product not found for ${service.provider}.${service.resource}.${action.actionId}`);
        action.product = product;
        return action;
      });
      return service;
    });

    return usageSummaries;
  }
}
